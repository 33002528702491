import { SearchBoxFeatureSuggestion } from '@mapbox/search-js-core';
import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { AddressAutocompleteV2 } from 'components/AddressAutocompleteV2';
import { ErrorTypography } from 'components/ErrorTypography';
import { TimeInput } from '../../../../../../TimeInput';
import { getTimeInputLabel } from '../../../utils';

interface Props {
  routePoint: Partial<RoutePoint>;
  error: UIError;
  onRoutePointTimeChange: (key: 'start_time' | 'end_time', value: string) => void;
  onAddressSuggestionSelected: (feature: SearchBoxFeatureSuggestion) => void;
  onSave: VoidFunction;
  editAddressEnabled: boolean;
  onEditAddress: VoidFunction;
  onCancelEditAddress: VoidFunction;
}

export function EditableRoutePoint(props: Props) {
  const {
    routePoint,
    onRoutePointTimeChange,
    error,
    onSave,
    onAddressSuggestionSelected,
    editAddressEnabled,
    onEditAddress,
    onCancelEditAddress,
  } = props;

  const getAddressContent = () => {
    const savedAddressDisplay = (
      <Stack direction="row" alignItems="baseline">
        <Typography>
          {routePoint?.point_location?.addressReadable || '- '}
        </Typography>
        <Button onClick={onEditAddress}>Edit</Button>
      </Stack>
    );

    const showingCancelEditAddressButton = routePoint
      ?.point_location?.addressReadable !== undefined;

    const addressInput = (

      <Stack direction="row" alignItems="baseline" spacing={1} sx={{ mt: '.5rem' }}>
        <Box width={showingCancelEditAddressButton ? '90%' : '100%'}>
          <AddressAutocompleteV2
            onSuggestionSelected={onAddressSuggestionSelected}
          />

        </Box>

        {routePoint?.point_location?.addressReadable && <Button onClick={onCancelEditAddress} variant="text">Cancel</Button>}
      </Stack>
    );

    return (
      <Stack sx={{ ml: '1rem' }}>
        {editAddressEnabled ? addressInput : savedAddressDisplay }
      </Stack>
    );
  };

  return (
    <Stack direction="column" flexWrap="wrap">
      <Box mb="1rem" display="flex" alignItems="center">
        <Typography sx={{ textTransform: 'capitalize' }} fontSize="1.1rem">
          {routePoint.stop_type?.toLowerCase()}
        </Typography>

      </Box>
      {getAddressContent()}
      <Stack direction="row" spacing={1} sx={{ ml: '1rem', mt: '1.5rem' }}>
        <Box>
          <TimeInput
            timeFormat="date"
            label={getTimeInputLabel(routePoint)[0]}
            onChange={(value) => onRoutePointTimeChange('start_time', value)}
            initialValue={routePoint.start_time}
          />
        </Box>
        <Box>
          <TimeInput
            label={getTimeInputLabel(routePoint)[1]}
            onChange={(value) => onRoutePointTimeChange('end_time', value)}
            initialValue={routePoint.end_time}
            timeFormat="date"
          />
        </Box>
      </Stack>
      <Stack direction="row" sx={{ ml: '1rem', mt: '1rem' }} alignContent="baseline" spacing={3}>
        <Button
          variant="outlined"
          onClick={onSave}
        >
          Save
        </Button>
      </Stack>
      <ErrorTypography error={error} />
    </Stack>
  );
}
