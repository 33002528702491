import CommuteIcon from '@mui/icons-material/Commute';
import { Typography } from '@mui/material';
import { IconText } from './IconText';

export function ShipmentEquipmentTypeText<T extends Equipment[]>(props: ShipmentInfoTextProps<T>) {
  const { data } = props;

  const equipmentTypes = data;

  const getEquipmentTypesDisplay = () => {
    let equipmentTypesDisplay = '';
    equipmentTypes.forEach((equipmentType, index) => {
      if (index === 0) {
        equipmentTypesDisplay = `${equipmentType.name}`;
      } else {
        equipmentTypesDisplay = `${equipmentTypesDisplay}, ${equipmentType.name}`;
      }
    });
    return equipmentTypesDisplay;
  };

  return (
    <IconText
      icon={<CommuteIcon />}
      text={(
        <Typography sx={{ textTransform: 'capitalize' }} variant="body1">
          {getEquipmentTypesDisplay()}
        </Typography>
      )}
    />

  );
}
