import { Stack, Typography } from '@mui/material';
import { CarrierCompanyNameAndReputationLabel } from 'components/CarrierCompanyNameAndReputationLabel';
import { NewTabLink } from 'components/NewTabLink';
import { AddCarrierToNetworkButton } from 'components/Shipper/components/AddCarrierToNetworkButton';
import { useCompanyProfile } from 'hooks';

interface Props {
  quote: ShipperCondensedQuote
}

export function CarrierCompanyQuoteDetail(props: Props) {
  const { quote } = props;
  const { companyProfile } = useCompanyProfile(quote?.created_by_company.id);
  return (
    <Stack
      direction="column"
      sx={{
        borderColor: '#cecdcd',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 2,
        p: '.3rem',
      }}
      spacing={1}
    >
      <Typography>
        <b>Carrier: </b>
        {' '}
        {quote?.created_by_company?.name || ''}
      </Typography>
      <Typography>
        <b>Insurance documents & more: </b>
        {companyProfile?.company?.temp_docs_directory_url
          ? <NewTabLink href={companyProfile.company.temp_docs_directory_url}>view</NewTabLink>
          : <Typography color="text.secondary">This carrier has not added their documents yet.</Typography>}
      </Typography>
      <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
        <Typography><b>Profile: </b></Typography>
        <CarrierCompanyNameAndReputationLabel
          companyId={quote?.created_by_company?.id}
          companyBusinessType={quote?.created_by_company?.business_type}
        />
      </Stack>
      <Stack direction="row" alignItems="flex-start">
        {!quote.is_partner_quote && (
        <AddCarrierToNetworkButton carrierId={quote.carrier_id} checkForRequestAlreadySent />
        )}
      </Stack>

    </Stack>
  );
}
