// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useAuthContext, useFetchingComponentState } from 'hooks';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { inLocalDevEnvironment } from 'utils';
import { TAWK_TO_PROPERTY_ID, TAWK_TO_WIDGET_ID } from '../../constants';

interface TawkToHashResponse {
  hash: string;
}

export function TawkToWrapper() {
  const { user } = useAuthContext();
  const [tawkToHash, setTawkToHash] = useState<string | null>(null);
  const [tawkToLoaded, setTawkToLoaded] = useState<boolean>(false);

  const {
    fetch, success,
  } = useFetchingComponentState<TawkToHashResponse>({
    errorMessage: '',
    fetchConfig: {
      url: '/user/tawkto-hash',
    },
  });

  const tawkMessengerRef = useRef();

  const identifyUser = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (tawkMessengerRef.current && tawkMessengerRef.current?.setAttributes) {
      if (!inLocalDevEnvironment()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        tawkMessengerRef.current.setAttributes({
          name: `${user?.first_name || 'Guest'}`,
          email: user?.email,
          hash: tawkToHash,
        });
      }
    }
  }, [tawkToHash, user]);

  const onLoad = () => {
    setTawkToLoaded(true);
  };

  useEffect(() => {
    if (user !== null && tawkToHash == null && !success) {
      fetch((res) => setTawkToHash(res.hash));
    }
  }, [user, tawkToHash, fetch, setTawkToHash, success]);

  useEffect(() => {
    if (tawkToHash && tawkToLoaded) {
      identifyUser();
    }
  }, [tawkToHash, identifyUser, tawkToLoaded]);

  return (
    <div>
      <TawkMessengerReact
        propertyId={TAWK_TO_PROPERTY_ID}
        widgetId={TAWK_TO_WIDGET_ID}
        onLoad={onLoad}
        ref={tawkMessengerRef}
      />
    </div>
  );
}
