import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  IconButton, Stack, Tooltip,
} from '@mui/material';

import { ShipmentListContainer } from 'components/Shipper/components/ShipmentListContainer';
import { ShipmentTemplateAndCreateShipmentFormDashboardItem } from 'components/Shipper/components/ShipmentTemplateAndCreateShipmentFormDashboardItem';
import { useShipments } from 'hooks';
import { DashboardItemLayout, PageLayout } from 'layouts';
import { useRef, useState } from 'react';
import { SHIPMENTS_PAGE_SIZE } from 'utils';

const MIN_LEFT_WIDTH = 40;
const MAX_LEFT_WIDTH = 55;

const MIN_RIGHT_WIDTH = 45;
const MAX_RIGHT_WIDTH = 60;

const DEFAULT_LEFT_WIDTH = 55;

interface Props {
  page: number;
  setPage: (page: number) => void;
}

export function HorizontalDashboard(props: Props) {
  const { page, setPage } = props;
  const {
    shipments, error, isLoading, count, refreshShipments,
  } = useShipments({ page, pageSize: SHIPMENTS_PAGE_SIZE });
  const leftSideRef = useRef<HTMLDivElement | null>(null);
  const rightSideRef = useRef<HTMLDivElement | null>(null);

  const [leftExpanded, setLeftExpanded] = useState(true);

  const setLeftWidth = (width: number) => {
    if (leftSideRef.current) {
      leftSideRef.current.style.width = `${width}%`;
    }
  };

  return (
    <PageLayout>
      <Stack
        direction="row"
        spacing={2}
      >
        <div
          ref={leftSideRef}
          style={{
            width: `${DEFAULT_LEFT_WIDTH}%`,
            display: 'flex',
            minWidth: `${MIN_LEFT_WIDTH}%`,
            maxWidth: `${MAX_LEFT_WIDTH}%`,
            marginRight: '.1rem',
          }}
        >
          <DashboardItemLayout
            style={{
              p: '1rem',
            }}
          >
            <ShipmentTemplateAndCreateShipmentFormDashboardItem
              refreshShipments={refreshShipments}

            />
          </DashboardItemLayout>

        </div>
        <div
          ref={rightSideRef}
          style={{
            flex: 1,
            display: 'flex',
            minWidth: `${MIN_RIGHT_WIDTH}%`,
            maxWidth: `${MAX_RIGHT_WIDTH}%`,
          }}
          id="rightSide"
        >
          <DashboardItemLayout
            style={{
              p: '1rem',
              width: '100%',
            }}
          >

            <ShipmentListContainer
              page={page}
              setPage={setPage}
              shipments={shipments}
              shipmentsError={error}
              shipmentsLoading={isLoading}
              shipmentCount={count}
              layoutAdjustments={(
                <Stack direction="row" justifyContent="start" sx={{ height: '.2rem' }}>
                  {leftExpanded ? (
                    <Tooltip title="Click to expand section">
                      <IconButton onClick={() => { setLeftWidth(MIN_LEFT_WIDTH); setLeftExpanded(false); }} size="small">
                        <ChevronLeftIcon />
                      </IconButton>
                    </Tooltip>
                  )
                    : (
                      <Tooltip title="Click to restore section">
                        <IconButton onClick={() => { setLeftWidth(DEFAULT_LEFT_WIDTH); setLeftExpanded(true); }} size="small">
                          <ChevronRightIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                </Stack>
              )}
            />
          </DashboardItemLayout>
        </div>
      </Stack>
    </PageLayout>
  );
}
