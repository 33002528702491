import {
  Box,
  Stack, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { DateTime } from 'luxon';
import { getAddressReadable } from './utils';

interface Props {
  routePoint: Partial<RoutePoint>;
  error: UIError;
  editButton: React.ReactNode;
  deleteMidRoutePointButton: React.ReactNode;
}

export function SavedRoutePoint(props: Props) {
  const {
    routePoint,
    editButton,
    error,
    deleteMidRoutePointButton,
  } = props;

  return (
    <Stack direction="column" spacing={1.5}>
      <Box display="flex" alignItems="center">
        <Typography sx={{ textTransform: 'capitalize' }} fontSize="1.1rem">
          {routePoint.stop_type?.toLowerCase()}
        </Typography>
      </Box>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6" color="text.secondary">
          {DateTime.fromISO(routePoint?.start_time ?? '').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </Typography>
        <Typography variant="h6" color="text.secondary">
          {getAddressReadable(routePoint)}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        {editButton}
        {routePoint.stop_type === 'MID' && deleteMidRoutePointButton}
      </Stack>
      <ErrorTypography error={error} />
    </Stack>
  );
}
