import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Typography } from '@mui/material';
import { IconText } from './IconText';

export function ShipmentCustomNameText<T>(props: ShipmentInfoTextProps<T>) {
  const { data } = props;

  return (
    <IconText
      icon={<LocalShippingIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
      text={(
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            maxWidth: '100%',
          }}
        >
          Custom Name:
          {' '}
          <b>{data}</b>
        </Typography>
)}
    />
  );
}
