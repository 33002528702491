import CheckIcon from '@mui/icons-material/Check';
import { TableCell, TableRow, Typography } from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';
import { AddCarrierToNetworkButton } from '../../AddCarrierToNetworkButton';

interface Props {
  carrierProgress: CarrierShipmentProgress
  followShipperRequestsSent: FollowShipperRequestAndResponse[] | null;
}

export function ShipmentProgressDetailTableRow(props: Props) {
  const { carrierProgress, followShipperRequestsSent } = props;

  const getUserColumnText = () => `${carrierProgress?.carrier?.user?.first_name || ''} - ${carrierProgress?.carrier?.company?.name || ''}`;
  return (
    <TableRow
      key={carrierProgress.carrier.user.id}
    >
      <TableCell>
        <Typography variant="body1">
          {carrierProgress?.carrier?.company
            ? (
              <NewTabLink href={`/companies/${carrierProgress?.carrier?.company.id}/profile`}>
                {getUserColumnText()}
              </NewTabLink>
            )
            : getUserColumnText()}
        </Typography>
      </TableCell>
      <TableCell>
        {carrierProgress.is_partner ? <CheckIcon /> : (
          <AddCarrierToNetworkButton
            carrierId={carrierProgress.carrier.id}
            checkForRequestAlreadySent={false}
            sentRequests={followShipperRequestsSent}
          />
        )}
      </TableCell>
      <TableCell>
        {carrierProgress.has_viewed ? <CheckIcon /> : <div />}
      </TableCell>
      <TableCell>
        {carrierProgress.has_quoted ? <CheckIcon /> : <div />}
      </TableCell>
    </TableRow>
  );
}
