import {
  Alert, Stack,
} from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';
import { TERMS_OF_SERVICE_URL } from '../../constants';

export function PersonalInformationDisclaimer() {
  return (
    <Stack>
      <Alert variant="outlined" severity="info" sx={{ mt: '1rem' }}>
        Including information such as email addresses and phone numbers could result in
        the suspension or deletion of your account in agreement with the Evotrux
        <NewTabLink href={TERMS_OF_SERVICE_URL}>
          <p>{' Terms of Service.'}</p>
        </NewTabLink>
      </Alert>
    </Stack>
  );
}
