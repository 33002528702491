import {
  Box, CircularProgress, Stack, Typography,
} from '@mui/material';
import { SubmitQuestionMenu } from 'components/SubmitQuestionMenu';
import { QuoteQADisplay } from '../QuoteQADisplay';

interface Props {
  error: string | null;
  loading: boolean;
  renderSubmitQuestion: boolean;
  qaList: QuoteQA[];
  quote: CondensedQuote;
}

export function QuoteQA(props: Props) {
  const {
    error, loading, renderSubmitQuestion, qaList, quote,
  } = props;

  if (loading) {
    return (
      <Stack alignItems="center" sx={{ mt: '1rem' }}>
        <CircularProgress />
      </Stack>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }

  const submitQuestionProps = {
    render: quote != null && quote !== undefined,
    textFieldLabel: 'Ask the carrier a question',
    postURL: `/quotes/${quote.quote}/questions`,
    addPadding: false,
  };

  if (!qaList || qaList.length < 1) {
    return (
      <Stack>
        {renderSubmitQuestion && (
        <Box>
          <SubmitQuestionMenu
            {...submitQuestionProps}
          />
        </Box>
        )}
        <Typography mt=".5rem" align="center">No questions have been asked for this quote.</Typography>

      </Stack>
    );
  }

  return (
    <Stack
      id="quote-qa-column"
      direction="column"
      justifyContent="space-between"
    >
      {renderSubmitQuestion && (
      <Box>
        <SubmitQuestionMenu
          {...submitQuestionProps}

        />
      </Box>
      )}
      <Stack spacing={1} pt="1rem">
        {qaList.map((quoteQA) => (
          <Box key={quoteQA.question.id}>
            <QuoteQADisplay qa={quoteQA} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}
