import { Alert, Box, Snackbar } from '@mui/material';
import { useAPI, useDisclosure } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isInvalidNumber } from 'utils';
import { validate } from 'validate.js';
import { CreateShipmentTemplateButton } from '../CreateShipmentTemplateButton';
import { CreateShipmentForm } from './CreateShipmentForm';
import { RoutePoint } from './components/RoutePoint';
import { RoutesForm } from './components/RoutesForm';
import { RoutesFormModal } from './components/RoutesFormModal';
import { PrivateShipmentGroupSelect } from './components/ShipmentNotificationsSelectMenu/components/PrivateShipmentGroupSelect';
import { createShipmentRequestBodyConstraints } from './constraints';
import {
  CreateShipmentBody,
  CreateShipmentFormState,
  CreateShipmentRouteBody,
  CreateShipmentShipmentBody,
} from './types';

const INITIAL_STATE: CreateShipmentFormState = {
  shipmentType: 'SINGLE',
  equipmentTypes: [],
  service: '',
  commodity: '',
  weightLb: '',
  footage: '',
  specialRequirements: '',
  additionalDetails: '',
  preferredPrice: '',
  routePoints: [
    {
      stop_type: 'ORIGIN',
      stop_operation: 'PICKUP',
      point_number: 1,
      start_time: DateTime.now().toFormat('yyyy-MM-dd'),
      end_time: DateTime.now().toFormat('yyyy-MM-dd'),
      isBeingEdited: true,
    },
    {
      stop_type: 'DESTINATION',
      stop_operation: 'DROPOFF',
      point_number: 2,
      start_time: DateTime.now().toFormat('yyyy-MM-dd'),
      end_time: DateTime.now().toFormat('yyyy-MM-dd'),
      isBeingEdited: true,
    },
  ],
  currency: null,
  notificationSettingsType: 'ALL_CARRIER_NETWORK',
  notificationSetingsEmails: [],
  commodityFreetext: '',
  isBookNow: false,
  bookNowPrice: '',
  bookNowCurrency: 'CAD',
  customName: '',
  emailNotificationType: 'ALL',
  shareeRoleIds: [],
  shareShipment: false,
  availableLoads: '',
  partnerGroups: [],
};

interface Props {
  shipmentState?: CreateShipmentFormState;
  commodity?: Commodity;
  error?: string | null;
  useCustomCommodity?: boolean;
  showShipmentNotificationSettings?: boolean;
  postShipmentUrl: string;
  onPreSubmitShipment?: (body: any) => void | null;
  submitShipment?: boolean;
  showCreateTemplate?: boolean;
  showBookNow?: boolean;
  onSubmitShipmentSuccessCallback?: VoidFunction | null
  showShareMenu?: boolean;
}

export function CreateShipmentFormContainer(props: Props) {
  const {
    shipmentState,
    error: copyError,
    commodity: copyCommodity,
    useCustomCommodity: useCustomCommodityCopy,
    showShipmentNotificationSettings,
    postShipmentUrl,
    onPreSubmitShipment,
    submitShipment,
    showCreateTemplate,
    showBookNow,
    onSubmitShipmentSuccessCallback,
    showShareMenu,
  } = props;
  const [formState, setFormState] = useState<CreateShipmentFormState>(INITIAL_STATE);
  const [commodity, setCommodity] = useState<Commodity | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: shipmentPostedPromptOpen,
    onClose: onCloseShipmentPostedDialog,
    onOpen: onOpenShipmentPostedDialog,
  } = useDisclosure(false);

  const [error, setError] = useState<string | null>(copyError || null);
  const { api } = useAPI();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [usingCustomCommodity, setUsingCustomCommodity] = useState<boolean>(true);

  useEffect(() => {
    setFormState(shipmentState !== undefined ? shipmentState : INITIAL_STATE);
  }, [shipmentState]);

  useEffect(() => {
    setCommodity(copyCommodity !== undefined ? copyCommodity : null);
  }, [copyCommodity]);

  useEffect(() => {
    if (useCustomCommodityCopy !== undefined) {
      setUsingCustomCommodity(useCustomCommodityCopy);
    }
  }, [useCustomCommodityCopy]);

  const handleOpen = () => {
    onOpen();
  };

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const handleValueChange = <T,>(key: keyof CreateShipmentFormState, value: T | null): void => {
    // validation here
    if (key === 'routePoints') {
      setFormState((prev) => {
        const rps = [...prev.routePoints];
        rps.splice(
          prev.routePoints.length - 1,
          0,
          value as Partial<RoutePoint>,
        );
        return {
          ...prev,
          routePoints: rps,
        };
      });
      onClose();
    } else if (key === 'commodity') {
      const commodityValue = value as unknown as Commodity;
      setFormState((prev) => ({
        ...prev,
        [key]: commodityValue?.code,
      }));
      setCommodity(commodityValue);
    } else if (key === 'equipmentTypes') {
      const equipmentValues = value as unknown as string[];
      setFormState((prev) => ({
        ...prev,
        equipmentTypes: equipmentValues,
      }));
    } else {
      setFormState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleRoutePointSave = (index: number, rp: Partial<RoutePoint>) => {
    setFormState((prev) => {
      const rps = [...prev.routePoints];
      // eslint-disable-next-line no-param-reassign
      rp.isBeingEdited = false;
      rps.splice(index, 1, rp);
      return {
        ...prev,
        routePoints: rps,
      };
    });
  };

  const onEditRoutePoint = (index: number, edit: boolean) => {
    setFormState((prev) => {
      const rps = [...prev.routePoints];
      rps[index].isBeingEdited = edit;
      return {
        ...prev,
        routePoints: rps,
      };
    });
  };

  const onDeleteMidRoutePoint = (index: number) => {
    setFormState((prev) => {
      const rps = [...prev.routePoints];
      rps.splice(index, 1);
      return {
        ...prev,
        routePoints: rps,
      };
    });
  };

  const routePointsAreValid = () => {
    const { routePoints } = formState;

    if (routePoints.length < 2) {
      setError('Please add at least 2 route points to your shipment.');
      return false;
    }

    return routePoints.every((rp, i) => {
      if (rp.start_time === undefined) {
        setError(`Route point ${i + 1} missing start time.`);
        return false;
      }
      if (!rp.end_time === undefined) {
        setError(`Route point ${i + 1} missing end time.`);
        return false;
      }
      if (!rp.point_location) {
        setError(`Route point ${i + 1} is missing location information address. Please enter one and select "save".`);
        return false;
      }
      if (!rp.point_location?.country) {
        setError(`Route point ${i + 1} is missing country information. Please choose a different location.`);
        return false;
      }
      if (!rp.point_location.latitude) {
        setError(`Route point ${i + 1} is missing latitutde. Please choose a different location.`);
        return false;
      }
      if (!rp.point_location.longitude) {
        setError(`Route point ${i + 1} is missing longitude. Please choose a different location.`);
        return false;
      }
      //  UNCOMMENT once legacy templates/shipment routes are updated to \
      //  include 'feature' in their route points
      //  if this is uncommented legacy routes will be marked as invalid
      //  when trying to post a shipment
      //  if (rp.point_location?.feature === undefined) {
      //    setError(`Route error. Please re-enter route point ${i + 1}.`);
      //  }
      return true;
    });
  };

  const additionalNotesAddedIfLTL = () => {
    const { service, additionalDetails } = formState;
    if (service === 'LESS_THAN_TRUCK_LOAD' && additionalDetails === '') {
      setError('Please provide the number of pieces and product dimensions for your LTL shipment in the Product Dimeneions field above.');
      return false;
    }
    return true;
  };

  const shipmentCommodityIsValid = () => {
    if (formState.commodity === '' && formState.commodityFreetext === '') {
      setError('Please enter a custom commodity or select one from the commodity list.');
      return false;
    }
    return true;
  };

  const bookNowIsValid = () => {
    if (formState.shipmentType === 'SINGLE' && formState.isBookNow && (formState.bookNowPrice === '0' || formState.bookNowPrice === '')) {
      setError('Please enter a Book Now price.');
      return false;
    }
    return true;
  };

  const buildRequestBodyRoutePoints = () => {
    const { routePoints } = formState;

    const bodyRps: CreateShipmentRouteBody[] = [];
    routePoints.forEach((rp, i) => {
      const { point_location: pointLocation, ...rest } = rp;
      const route = { ...rest } as RoutePoint;
      bodyRps.push({
        point: {
          ...route,
          point_number: i + 1,
          start_time: route.start_time,
          end_time: route.end_time,
        },
        point_location: pointLocation as RoutePointLocation,
      });
    });

    return bodyRps;
  };

  const getShipmentBody = (): CreateShipmentBody => {
    // parses the form state and returns a shipment object ready for posting.
    // Does not validate that the shipment form contains all necessary fields or correct values
    let service = null;
    if (formState.service !== '') {
      service = formState.service;
    }
    const shipment = {
      service,
      type: formState.shipmentType,
      weight_lb: parseFloat(formState.weightLb),
      footage: formState.footage === '' ? null : formState.footage,
      preferred_price: formState.preferredPrice === '' ? null : formState.preferredPrice,
      currency: formState.currency,
      commodity: usingCustomCommodity ? '' : formState.commodity,
      special_requirements: formState.specialRequirements,
      additional_details: formState.additionalDetails,
      commodity_freetext: usingCustomCommodity ? formState.commodityFreetext : '',
      book_now_currency: formState.shipmentType === 'SINGLE' && formState.isBookNow ? formState.bookNowCurrency : null,
      book_now_price: formState.shipmentType === 'SINGLE' && formState.isBookNow ? parseFloat(formState.bookNowPrice) : null,
      custom_name: formState.customName === '' ? null : formState.customName,
    };

    const route = buildRequestBodyRoutePoints();
    const equipments = formState.equipmentTypes;
    const createShipmentRequestBody: CreateShipmentBody = {
      shipment,
      route,
      equipment_types: equipments,
      notification_settings_type: formState.notificationSettingsType,
      notification_settings_emails: formState.notificationSetingsEmails,
      notification_settings_partner_groups: formState.partnerGroups,
      email_notification_settings: formState.emailNotificationType,
      sharee_role_ids: formState.shareShipment ? formState.shareeRoleIds : [],
      project: formState.shipmentType === 'MULTI_SHIPMENT_PROJECT' ? { loads_available: parseInt(formState.availableLoads, 10) } : null,
    };
    return createShipmentRequestBody;
  };

  const onShipmentSubmitSuccess = () => {
    // call the callbacks & scroll the page to the top
    if (onSubmitShipmentSuccessCallback) {
      onSubmitShipmentSuccessCallback();
    }
    const div = document.getElementById('main-content');
    if (div) {
      div.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleSubmitShipment = () => {
    const shipment: CreateShipmentShipmentBody = {
      service: formState.service,
      type: formState.shipmentType,
      weight_lb: formState.weightLb,
      footage: formState.footage === '' ? null : formState.footage,
      preferred_price: formState.preferredPrice === '' ? null : formState.preferredPrice,
      currency: formState.currency,
      commodity: usingCustomCommodity ? '' : formState.commodity,
      special_requirements: formState.specialRequirements,
      additional_details: formState.additionalDetails,
      commodity_freetext: usingCustomCommodity ? formState.commodityFreetext : '',
      book_now_currency: formState.shipmentType === 'SINGLE' && formState.isBookNow ? formState.bookNowCurrency : null,
      book_now_price: formState.shipmentType === 'SINGLE' && formState.isBookNow ? formState.bookNowPrice : null,
      custom_name: formState.customName === '' ? null : formState.customName,
    };
    if (!routePointsAreValid()) {
      return;
    }
    if (!additionalNotesAddedIfLTL()) {
      return;
    }
    if (!shipmentCommodityIsValid()) {
      return;
    }
    if (!bookNowIsValid()) {
      return;
    }
    const route = buildRequestBodyRoutePoints();
    const equipments = formState.equipmentTypes;
    const createShipmentRequestBody: CreateShipmentBody = {
      shipment,
      route,
      equipment_types: equipments,
      notification_settings_type: formState.notificationSettingsType,
      notification_settings_emails: formState.notificationSetingsEmails,
      notification_settings_partner_groups: formState.partnerGroups,
      email_notification_settings: formState.emailNotificationType,
      sharee_role_ids: formState.shareShipment ? formState.shareeRoleIds : [],
      project: formState.shipmentType === 'MULTI_SHIPMENT_PROJECT' ? { loads_available: parseInt(formState.availableLoads, 10) } : null,
    };

    if (isInvalidNumber(formState.weightLb)) {
      setError('Weight must be a number.');
      return;
    }

    if (formState.isBookNow && isInvalidNumber(formState.bookNowPrice)) {
      setError('Book Now price must be a number.');
      return;
    }

    if (formState.shipmentType === 'MULTI_SHIPMENT_PROJECT' && isInvalidNumber(formState.availableLoads)) {
      setError('Available project loads must be a number.');
      return;
    }

    const shipmentBodyErrors = validate(
      createShipmentRequestBody,
      createShipmentRequestBodyConstraints,
    );

    if (!shipmentBodyErrors) {
      if (onPreSubmitShipment) {
        onPreSubmitShipment(createShipmentRequestBody);
      }
      if (submitShipment) {
        setSubmitLoading(true);
        api('post', postShipmentUrl, createShipmentRequestBody).then(() => {
          setError(null);
          onOpenShipmentPostedDialog();
          setFormState(INITIAL_STATE);
          onShipmentSubmitSuccess();
        }).catch((e) => {
          setError(e?.response?.data?.message || 'Failed to create shipment. Please contact us if the problem persists.');
          setSubmitLoading(false);
        }).finally(() => {
          setSubmitLoading(false);
        });
      }
    }
    // display the first validation error message.
    /*
      Sample structure of validation errors:
      {
        "equipment_type": [
            "Equipment type Invalid Equipment Type"
        ],
      }
      */
    if (shipmentBodyErrors != null && shipmentBodyErrors !== undefined) {
      const errorKeys = Object.keys(shipmentBodyErrors);
      if (errorKeys.length > 0 && errorKeys[0].length > 0) {
        setError(shipmentBodyErrors[errorKeys[0]][0]);
      }
    }
  };

  const moveRoutePoint = useCallback((dragIndex: number, hoverIndex: number) => {
    setFormState((prev) => {
      if (hoverIndex === 0 || hoverIndex === prev.routePoints.length - 1) {
        return prev;
      }
      const prevPoints = prev.routePoints;
      const originalItem = prev.routePoints[dragIndex];
      prevPoints.splice(dragIndex, 1);
      prevPoints.splice(hoverIndex, 0, originalItem);
      return {
        ...prev,
        routePoints: prevPoints,
      };
    });
  }, []);

  const setShipmentNotificationType = (type: ShipmentNotificationType) => {
    handleValueChange('notificationSettingsType', type);
  };

  const setShipmentNotificationEmails = (emails: string[]) => {
    handleValueChange('notificationSetingsEmails', emails);
  };

  const setShareeRoleIds = (roleIds: number[]) => {
    handleValueChange('shareeRoleIds', roleIds);
  };

  const setShareShipment = (newValue: boolean) => {
    handleValueChange('shareShipment', newValue);
  };

  const getShowBookNow = () => {
    if (formState.shipmentType === 'MULTI_SHIPMENT_PROJECT') {
      return false;
    }
    return showBookNow ?? true;
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Snackbar
        open={shipmentPostedPromptOpen}
        autoHideDuration={3000}
        onClose={onCloseShipmentPostedDialog}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={onCloseShipmentPostedDialog}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Shipment Created!
        </Alert>
      </Snackbar>
      <CreateShipmentForm
        equipmentTypes={formState.equipmentTypes}
        routePoints={formState.routePoints.map((rp, i) => (
          <RoutePoint
            key={`${rp.start_time}-${i.toString()}-${Math.floor(Math.random() * 1000)}`}
            index={i}
            routePoint={rp}
            onSave={handleRoutePointSave}
            move={moveRoutePoint}
            onDeleteMidpoint={onDeleteMidRoutePoint}
            onEditRoutePoint={onEditRoutePoint}
          />
        ))}
        commodity={commodity}
        serviceType={formState.service}
        weightLb={formState.weightLb}
        specialRequirements={formState.specialRequirements}
        additionalDetails={formState.additionalDetails}
        error={error}
        onValueChanged={handleValueChange}
        toggleCreateRouteOpen={handleOpen}
        onSubmit={handleSubmitShipment}
        submitLoading={submitLoading}
        setShipmentNotificationType={setShipmentNotificationType}
        setShipmentNotificationEmails={setShipmentNotificationEmails}
        usingCustomCommodity={usingCustomCommodity}
        onUseCustomCommodityChanged={setUsingCustomCommodity}
        commodityFreetext={formState.commodityFreetext}
        shipmentNotificationType={formState.notificationSettingsType}
        selectedCarriers={formState.notificationSetingsEmails}
        showShipmentNotificationSettings={showShipmentNotificationSettings}
        createTemplateButton={showCreateTemplate ? (
          <CreateShipmentTemplateButton
            getShipmentBody={getShipmentBody}
          />
        ) : <Box />}
        showBookNow={getShowBookNow()}
        bookNowPrice={formState.bookNowPrice}
        bookNowCurrency={formState.bookNowCurrency}
        isBookNow={formState.isBookNow}
        customName={formState.customName}
        emailNotificationType={formState.emailNotificationType}
        showShareMenu={showShareMenu}
        setShareeRoleIds={setShareeRoleIds}
        selectedSharees={formState.shareeRoleIds}
        onShareShipmentChange={setShareShipment}
        shareShipment={formState.shareShipment}
        shipmentType={formState.shipmentType}
        availableLoads={formState.availableLoads}
        groupSelectComponent={(
          <PrivateShipmentGroupSelect
            selectedGroups={formState.partnerGroups.map((g) => g.toString())}
            handleChange={(groups) => handleValueChange('partnerGroups', groups)}
          />
        )}
      />
      <RoutesFormModal
        open={isOpen}
        onClose={onClose}
        form={(
          <RoutesForm
            onSubmit={(value) => handleValueChange<Partial<RoutePoint>>('routePoints', value)}
          />
)}

      />
    </DndProvider>
  );
}

CreateShipmentFormContainer.defaultProps = {
  shipmentState: INITIAL_STATE,
  commodity: null,
  error: null,
  useCustomCommodity: true,
  showShipmentNotificationSettings: true,
  onPreSubmitShipment: null,
  submitShipment: true,
  showCreateTemplate: true,
  showBookNow: true,
  onSubmitShipmentSuccessCallback: null,
  showShareMenu: false,
};
