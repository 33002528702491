import { SearchBoxFeatureSuggestion } from '@mapbox/search-js-core';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { getRoutePointLocationFromMapBoxFeature } from 'utils';
import { areRouteTimesValid } from '../../utils';
import { RoutesForm } from './RoutesForm';

interface Props {
  onSubmit: (routePoint: Partial<RoutePoint>) => void;
}

export function RoutesFormContainer(props: Props) {
  const { onSubmit } = props;
  const [routePointState, setRoutePointState] = useState<Partial<RoutePoint>>({
    stop_operation: 'PICKUP',
    stop_type: 'MID',
    start_time: DateTime.now().toFormat('yyyy-MM-dd'),
    end_time: DateTime.now().toFormat('yyyy-MM-dd'),
    isBeingEdited: false,
  });
  const [error, setError] = useState<string | null>(null);

  const onAddressSuggestionSelected = (feature: SearchBoxFeatureSuggestion) => {
    const location = getRoutePointLocationFromMapBoxFeature(feature);
    setRoutePointState((prev) => ({
      ...prev,
      point_location: location as RoutePointLocation,
    }));
  };

  const onRoutePointMetadataChange = (key: 'start_time' | 'end_time' | 'stop_operation', value: string) => {
    setRoutePointState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validate = (): boolean => {
    const {
      start_time: startTime,
      end_time: endTime,
      point_location: pointLocation,
    } = routePointState;
    if (pointLocation === undefined) {
      setError('Address can not be empty.');
      return false;
    }
    if (areRouteTimesValid(startTime, endTime) != null) {
      setError(areRouteTimesValid(startTime, endTime));
      return false;
    }
    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    onSubmit(routePointState);
  };

  return (
    <RoutesForm
      routePoint={routePointState}
      onSubmit={handleSubmit}
      onRoutePointMetadataChange={onRoutePointMetadataChange}
      onAddressSuggestionSelected={onAddressSuggestionSelected}
      error={error}
    />
  );
}
