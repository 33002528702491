import { Typography } from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';

interface Props {
  userIsAuthor: boolean;
  fileName: string;
  fileURL: string;
}

export function FileMessageDisplay(props: Props) {
  const { userIsAuthor, fileName, fileURL } = props;
  return (
    <Typography
      variant="body1"
      textAlign={userIsAuthor ? 'right' : 'left'}
    >
      <NewTabLink href={fileURL}><p>{fileName}</p></NewTabLink>
    </Typography>
  );
}
