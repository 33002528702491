import { Typography } from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../../../constants';

export function CompanyNameUpgradeText() {
  return (
    <NewTabLink href={CARRIER_UPGRADE_RELATIVE_URL}>
      <Typography>
        Upgrade to view company name
      </Typography>
    </NewTabLink>
  );
}
