import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
  Box, Stack, Tooltip, Typography,
} from '@mui/material';

interface Props {
  quote: CondensedQuote
}

export function QuotePreviewShipmentIDText(props: Props) {
  const { quote } = props;
  const shipmentId = quote?.shipment || '';
  return (
    <Box display="flex" alignItems="center">
      <Tooltip
        title={quote.quote_type === 'MULTI_SHIPMENT_PROJECT'
          ? `Project ID: ${shipmentId}` : `Shipment ID: ${shipmentId}`}
        followCursor
      >
        <Stack direction="row" alignItems="center">
          <LocalShippingIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />
          <Typography>
            {`ID ${shipmentId}`}
          </Typography>
        </Stack>
      </Tooltip>
    </Box>
  );
}
