import {
  Chip, Typography,
} from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';
import { useShipmentQuoteCount, useSubscriptionStatus } from 'hooks';
import { hasPaidCarrierSubscription } from 'utils';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../../../constants';

interface Props {
  shipment: CarrierCondensedShipment
}

export function QuoteCountChip(props: Props) {
  const { shipment } = props;
  const { quoteCount } = useShipmentQuoteCount(shipment?.info?.id);

  const { subscription } = useSubscriptionStatus();

  if (subscription == null) {
    return <div />;
  }
  if (!hasPaidCarrierSubscription(subscription)) {
    return (

      <NewTabLink href={CARRIER_UPGRADE_RELATIVE_URL}>
        <Typography>
          Upgrade to view quote count
        </Typography>
      </NewTabLink>
    );
  }
  if (quoteCount == null) {
    return <div />;
  }

  let text = 'quotes';
  if (quoteCount === 1) {
    text = 'quote';
  }

  return (
    <Chip
      label={(
        <div className="quotes">
          {`${quoteCount} ${text}`}
        </div>
  )}
      variant="outlined"
      color="info"
      size="small"
    />
  );
}
