import {
  Alert,
  Snackbar,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import {
  useAuthContext,
  useCarrierCompanyBookingLimits,
  useCarrierShipmentPageContext,
  useDisclosure,
  useShipmentQuotes,
  useSubscriptionStatus,
  useWriterComponentState,
} from 'hooks';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  extractDateFromIsoString, isInvalidNumber, naiveDateToISOString, startLessOrEqualToEnd,
} from '../../../../utils';
import { MultiShipmentSubmitQuoteForm } from './MultiShipmentSubmitQuoteForm';
import { MultiShipmentPricingModal } from './components/MultiShipmentPricingModal';
import { SubmitMultiShipmentProjectQuoteFormState } from './types';
import { showPricingAdvisoryModal } from './utils';

const INIITAL_STATE: SubmitMultiShipmentProjectQuoteFormState = {
  equipmentType: '',
  pickupStart: '',
  pickupStartTime: '',
  pickupEnd: '',
  pickupEndTime: '',
  currency: 'CAD',
  price: '',
  notes: '',
  trackingLinkOffered: false,
  minimumLoads: '',
  maxLoads: '',
};

interface Props {
  shipment: CarrierCondensedShipment;

}

export function MultiShipmentSubmitQuoteFormContainer(props: Props) {
  const { shipment } = props;
  const { user } = useAuthContext();
  const { onRefresh } = useCarrierShipmentPageContext();
  const { refetchLimits } = useCarrierCompanyBookingLimits();
  const [formValidationError, setFormValidationError] = useState<UIError>(null);

  const [formState, setFormState] = useState<
  SubmitMultiShipmentProjectQuoteFormState>(INIITAL_STATE);
  const [canSubmitQuote, setCanSubmitQuote] = useState<boolean>(true);
  const shipmentRef = useRef<undefined | CondensedShipment>();
  const { subscription } = useSubscriptionStatus();
  const {
    isOpen: pricingAdivosryModalOpen,
    onClose: onClosePricingAdvisoryModal,
    onOpen: onOpenPricingAdvisoryModal,
  } = useDisclosure(false);

  const {
    isOpen: snackbarOpened,
    onClose: onSnackbarClose,
    onOpen: openSnackbar,
  } = useDisclosure(false);

  const {
    write, error, loading,
  } = useWriterComponentState({
    errorMessage: 'Failed to submit quote. Please contact us if the problem persists.',
    errorMessageKey: 'message',
    writeConfig: {
      method: 'post',
      url: `/shipments/${shipment.info.id}/quotes`,
      body: {
        equipment_type: formState.equipmentType,
        pickup_start: formState.pickupStart === '' ? '' : naiveDateToISOString(formState.pickupStart),
        pickup_start_time: null,
        pickup_end: formState.pickupEnd === '' ? '' : naiveDateToISOString(formState.pickupEnd),
        pickup_end_time: null,
        price: formState.price,
        currency: formState.currency,
        notes: formState.notes,
        tracking_link_offered: formState.trackingLinkOffered,
        min_loads_desired: formState.minimumLoads,
        max_loads_desired: formState.maxLoads,
      },
    },
  });

  const {
    quotes,
    refetchQuotes,
    isLoading: quoteRefetchLoading,
  } = useShipmentQuotes(shipment?.info.id ?? 0);
  const findCarrierActiveShipment = useCallback((q: CondensedQuote) => (q.internal_state === 'ACTIVE' || q.internal_state === 'COUNTERED') && q.created_by.id === user?.id, [user?.id]);
  /*
  Runs whenever the Shipment prop changes. This useEffect takes care
  of reacting to changes on the shipment status which results in rendering/not rendering
  the submit quote menu.

  If the shipment ID has changed between renders redetermine if the submit quote menu must be shown.
  Additionally, set the form state to default empty, since the shipment being viewed has changed.
  */
  useEffect(() => {
    if (shipment && (shipmentRef.current?.info?.id !== shipment.info.id)) {
      shipmentRef.current = shipment;
      setCanSubmitQuote(true);
      refetchQuotes();
      setFormState(INIITAL_STATE);
      refetchLimits();
    }
  }, [shipment, refetchQuotes, refetchLimits]);

  /*
  Runs when the shipment version changes (when the shipment is refreshed)
  If the shipment version has changed
  changed between renders redetermine if the submit quote menu must be shown.

  */
  useEffect(() => {
    if (shipment && (shipmentRef.current && shipmentRef.current.version !== shipment.version
    )) {
      shipmentRef.current = shipment;
      setCanSubmitQuote(true);
      refetchQuotes();
      refetchLimits();
    }
  }, [shipment, refetchQuotes, refetchLimits]);

  useEffect(() => {
    if (quotes && quotes.length > 0) {
      const result = !quotes.some((q) => findCarrierActiveShipment(q));
      setCanSubmitQuote(result);
    } else {
      setCanSubmitQuote(true);
    }
  }, [quotes, setCanSubmitQuote, findCarrierActiveShipment]);

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const onValueChanged = <T,>(key: keyof SubmitMultiShipmentProjectQuoteFormState,
    value: T): void => {
    setFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateForm = () => {
    if (!startLessOrEqualToEnd(formState.pickupStart, formState.pickupEnd)) {
      return 'Pickup start must be before or equal to Pickup end.';
    }
    if (isInvalidNumber(formState.price)) {
      return 'Price must be a number.';
    }
    if (isInvalidNumber(formState.minimumLoads)) {
      return 'Minimum loads must be a number.';
    }

    if (isInvalidNumber(formState.maxLoads)) {
      return 'Max loads must be a number.';
    }

    if (parseInt(formState.minimumLoads, 10) > parseInt(formState.maxLoads, 10)) {
      return 'Max loads must be greater than or equal to minimum loads.';
    }

    if (formState.equipmentType === '') {
      return 'Equipment type is required.';
    }

    if (parseInt(formState.minimumLoads, 10) < 1) {
      return 'Minimum loads must be greater than 0.';
    }

    if (parseInt(formState.maxLoads, 10) < 1) {
      return 'Max loads must be greater than 0.';
    }

    if (shipment.project?.loads_available !== undefined
      && parseInt(formState.minimumLoads, 10) > shipment.project?.loads_available) {
      return `Minimum loads must be less than or equal to available loads (${shipment.project?.loads_available}).`;
    }

    return null;
  };

  const refreshPage = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  const submitQuote = () => {
    write(() => {
      refreshPage();
      setFormValidationError(null);
    });
  };

  const onSubmit = () => {
    setFormValidationError(null);
    const errors = validateForm();
    if (errors != null) {
      setFormValidationError(errors);
    } else {
      // case where pricing advise modal is not shown
      // eslint-disable-next-line
      if (subscription
        && !showPricingAdvisoryModal(
          subscription.subscription_plan,
          shipment.is_partner_shipment,
        )) {
        submitQuote();
      } else {
        onOpenPricingAdvisoryModal();
      }
    }
  };

  const onModalPricingCancelled = () => {
    onClosePricingAdvisoryModal();
  };

  const onModalPricingSubmit = () => {
    onClosePricingAdvisoryModal();
    submitQuote();
    refreshPage();
  };

  const onPopulateShipmentRequirements = () => {
    onValueChanged('pickupStart', extractDateFromIsoString(shipment.first_point.start_time));
    onValueChanged('pickupEnd', extractDateFromIsoString(shipment.last_point.start_time));

    if (shipment.equipments.length > 0) {
      onValueChanged('equipmentType', shipment.equipments[0].type);
    }
    openSnackbar();
  };

  return (
    <>
      {pricingAdivosryModalOpen
      && subscription
      && showPricingAdvisoryModal(subscription.subscription_plan, shipment.is_partner_shipment) && (
      <MultiShipmentPricingModal
        onModalClose={onModalPricingCancelled}
        onAcknowledge={onModalPricingSubmit}
        isOpen={pricingAdivosryModalOpen}
      />
      )}
      <MultiShipmentSubmitQuoteForm
        onValueChanged={onValueChanged}
        onSubmit={onSubmit}
        error={error}
        loading={loading}
        canSubmitQuote={canSubmitQuote}
        formState={formState}
        quoteRefetchLoading={quoteRefetchLoading}
        formValidationError={formValidationError}
        onPopulateShipmentRequirements={onPopulateShipmentRequirements}
      />
      <Snackbar
        id="customerRequierementsPopulatedSnackbar"
        open={snackbarOpened}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert onClose={onSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Requirements applied!
        </Alert>
      </Snackbar>
    </>
  );
}
