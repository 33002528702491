import { SearchBoxFeatureSuggestion } from '@mapbox/search-js-core';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useDraggable } from 'hooks';
import { useState } from 'react';
import { getRoutePointLocationFromMapBoxFeature } from 'utils';
import { areRouteTimesValid } from '../../utils';
import { RoutePoint } from './RoutePoint';

interface Props {
  routePoint: Partial<RoutePoint>;
  index: number;
  onSave: (index: number, rp: Partial<RoutePoint>) => void;
  move: (dragIndex: number, hoverIndex: number) => void;
  onDeleteMidpoint: (index: number) => void;
  onEditRoutePoint: (index: number, edit: boolean) => void;
}

export function RoutePointContainer(props: Props) {
  const {
    routePoint,
    index,
    move,
    onSave,
    onDeleteMidpoint,
    onEditRoutePoint,
  } = props;
  const { ref, handlerId, opacity } = useDraggable<Partial<RoutePoint>>('RoutePoint', index, routePoint, move);
  const [error, setError] = useState<string | null>(null);
  const [
    routePointState,
    setRoutePointState] = useState<Partial<RoutePoint>>(routePoint);

  const isEditing = routePoint?.isBeingEdited ?? true;

  const isOriginOrDest = ['ORIGIN', 'DESTINATION'].includes(
    routePoint?.stop_type ?? '',
  );

  const onRoutePointTimeChange = (key: 'start_time' | 'end_time', value: string) => {
    setRoutePointState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSave = () => {
    if (areRouteTimesValid(routePointState.start_time, routePointState.end_time) != null) {
      setError(areRouteTimesValid(routePointState.start_time, routePointState.end_time));
      return;
    }
    setError(null);
    setRoutePointState((prev) => ({
      ...prev,
      isBeingEdited: false,
    }));
    onEditRoutePoint(index, false);
    onSave(index, routePointState);
  };

  const onAddressSuggestionSelected = (feature: SearchBoxFeatureSuggestion) => {
    const location = getRoutePointLocationFromMapBoxFeature(feature);
    setRoutePointState((prev) => ({
      ...prev,
      point_location: location as RoutePointLocation,
    }));
  };

  const routePointComponent = (
    <RoutePoint
      routePoint={routePointState}
      isEditing={isEditing}
      error={error}
      onRoutePointTimeChange={onRoutePointTimeChange}
      onAddressSuggestionSelected={onAddressSuggestionSelected}
      editButton={(
        <Button
          variant="outlined"
          onClick={() => onEditRoutePoint(index, true)}
        >
          Edit
        </Button>
      )}
      onSave={handleSave}
      deleteMidRoutePointButton={(
        <Tooltip
          title="Delete midpoint"
        >
          <IconButton onClick={() => onDeleteMidpoint(index)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>

    )}
    />
  );

  if (isOriginOrDest) {
    return <div style={{ cursor: 'default' }}>{routePointComponent}</div>;
  }

  return (
    <div ref={ref} data-handler-id={handlerId} style={{ opacity, cursor: 'move' }}>{routePointComponent}</div>
  );
}
