import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton, Stack, Tooltip, Typography, Zoom,
} from '@mui/material';
import { ShipmentDetailTitle } from '../ShipmentDetailTitle';

interface Props {
  shipment: ShipperCondensedShipment | undefined;
  onOpenTextBox: VoidFunction;
  textBoxDialog: React.ReactNode;
}

export function EditableShipmentDetailTitle(props: Props) {
  const {
    shipment,
    onOpenTextBox,
    textBoxDialog,
  } = props;

  const getShipmentTitleNode = () => {
    if (!shipment) {
      return (
        <Typography variant="h3">
          Shipment -
        </Typography>
      );
    } if (shipment.custom_name != null) {
      let shipmentNoun = 'Shipment';
      if (shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT') {
        shipmentNoun = 'Project';
      }
      return (
        <Tooltip title={`${shipmentNoun} ${shipment?.info?.id ?? '-'}`} placement="right" TransitionComponent={Zoom}>
          <Typography
            variant="h3"
            sx={{
              whiteSpace: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-word',
              maxWidth: '100%',
            }}
          >
            {shipment.custom_name}
          </Typography>
        </Tooltip>
      );
    }
    return (
      <ShipmentDetailTitle shipment={shipment} />
    );
  };

  return (
    <Stack direction="row">
      {getShipmentTitleNode()}
      <Tooltip followCursor title="Edit Custom Name" placement="right" TransitionComponent={Zoom}>
        <IconButton color="primary" onClick={onOpenTextBox}>
          <EditIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      {textBoxDialog}
    </Stack>
  );
}
