import { SearchBoxFeatureSuggestion } from '@mapbox/search-js-core';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography,
} from '@mui/material';
import { AddressAutocompleteV2 } from 'components/AddressAutocompleteV2';
import { DateTime } from 'luxon';
import { TimeInput } from '../../../../../TimeInput';
import { AddStopButton } from '../AddStopButton';

interface Props {
  routePoint: Partial<RoutePoint>;
  error: string | null;
  onRoutePointMetadataChange: (key: 'start_time' | 'end_time' | 'stop_operation', value: string) => void;
  onAddressSuggestionSelected: (feature: SearchBoxFeatureSuggestion) => void;
  onSubmit: VoidFunction;
}

export function RoutesForm(props: Props) {
  const {
    error,
    routePoint,
    onSubmit,
    onRoutePointMetadataChange,
    onAddressSuggestionSelected,
  } = props;

  return (
    <Stack mb="2rem" spacing={4}>
      <FormControl>
        <FormLabel>
          Operation
        </FormLabel>
        <RadioGroup
          row
          value={routePoint.stop_operation}
          onChange={(e) => onRoutePointMetadataChange('stop_operation', e.target.value)}
        >
          <FormControlLabel value="PICKUP" control={<Radio />} label="Pickup" />
          <FormControlLabel value="DROPOFF" control={<Radio />} label="Drop Off" />
        </RadioGroup>
      </FormControl>
      <AddressAutocompleteV2
        onSuggestionSelected={onAddressSuggestionSelected}
      />
      <TimeInput
        timeFormat="date"
        initialValue={routePoint?.end_time || DateTime.now().toFormat('yyyy-MM-dd')}
        label={routePoint.stop_operation === 'PICKUP' ? 'Earliest Pickup' : 'Earliest Dropoff'}
        onChange={(value) => onRoutePointMetadataChange('start_time', value)}
      />
      <TimeInput
        timeFormat="date"
        initialValue={routePoint?.start_time || DateTime.now().toFormat('yyyy-MM-dd')}
        label={routePoint.stop_operation === 'PICKUP' ? 'Latest Pickup' : 'Latest Dropoff'}
        onChange={(value) => onRoutePointMetadataChange('end_time', value)}
      />
      {error != null && <Typography sx={{ mt: '1rem', mb: '1rem' }} color="red">{error}</Typography>}
      <AddStopButton onClick={onSubmit} />
    </Stack>
  );
}
