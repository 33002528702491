import { Stack } from '@mui/material';

import { SearchBoxFeatureSuggestion, SearchBoxRetrieveResponse } from '@mapbox/search-js-core';
import { SearchBox } from '@mapbox/search-js-react';
import { ErrorTypography } from 'components/ErrorTypography';
import { useState } from 'react';
import { MapBoxLocationType } from './types';

// TOUCH PAD PROBLEM https://stackoverflow.com/questions/76382143/mapbox-searchbox-do-not-support-mouse-click-when-selecting-a-suggestion

interface Props {
  onSuggestionSelected: (suggestion: SearchBoxFeatureSuggestion) => void;
  locationTypes?: Set<MapBoxLocationType>; // limits search results to the given location types.
  // https://docs.mapbox.com/mapbox-search-js/api/core/search/#searchboxadministrativeunittype
}

const TOKEN = process.env.REACT_APP_MAPBOX_PUBLIC_KEY || 'ERROR';

export function AddressAutocompleteContainerV2(props: Props) {
  const {
    onSuggestionSelected, locationTypes,
  } = props;
  const [query, setQuery] = useState<string>('');
  const [queryError, setQueryError] = useState<UIError>(null);

  const onRetrieve = (res: SearchBoxRetrieveResponse) => {
    // suggestion is a collection of GeoJSON Feature
    // https://docs.mapbox.com/mapbox-search-js/api/core/search/#searchboxfeaturesuggestion
    if (res.features.length > 0) {
      onSuggestionSelected(res.features[0]);
    }
  };

  const onSuggestError = () => {
    setQueryError(
      'Failed to retrieve suggestions',
    );
  };

  return (
    <div className="mapbox-searchbox-wrapper">
      <Stack direction="column">
        <SearchBox
          accessToken={TOKEN}
          onRetrieve={onRetrieve}
          onSuggestError={onSuggestError}
          onChange={setQuery}
          value={query}
        // options https://docs.mapbox.com/mapbox-search-js/api/core/search/#searchboxoptions
          options={{
            language: 'en',
            types: locationTypes,
            limit: 10,
          }}
          // add label 'address etc' as placeholder
          theme={{
            variables: {
              boxShadow: '0 0 0 1px silver',
            },

          }}
        />
        <ErrorTypography error={queryError} />
      </Stack>
    </div>
  );
}

AddressAutocompleteContainerV2.defaultProps = {
  locationTypes: undefined,
};
