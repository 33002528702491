import { SearchBoxFeatureSuggestion } from '@mapbox/search-js-core';
import { Box } from '@mui/material';
import { useDisclosure } from 'hooks';
import { EditableRoutePoint, SavedRoutePoint } from './components';

interface Props {
  routePoint: Partial<RoutePoint>;
  isEditing: boolean;
  error: UIError;
  onRoutePointTimeChange: (key: 'start_time' | 'end_time', value: string) => void;
  editButton: React.ReactNode;
  onAddressSuggestionSelected: (feature: SearchBoxFeatureSuggestion) => void;
  onSave: VoidFunction;
  deleteMidRoutePointButton: React.ReactNode;
}

export function RoutePoint(props: Props) {
  const {
    routePoint,
    isEditing,
    onRoutePointTimeChange,
    editButton,
    error,
    onSave,
    onAddressSuggestionSelected,
    deleteMidRoutePointButton,
  } = props;

  const {
    isOpen: editAddressEnabled,
    onOpen: onEditAddress,
    onClose: onAddressNotEditable,
  } = useDisclosure(routePoint?.point_location === undefined);

  const handleAddressSuggestionSelected = (feature: SearchBoxFeatureSuggestion) => {
    onAddressSuggestionSelected(feature);
    onAddressNotEditable();
  };

  let component;
  if (isEditing) {
    component = (
      <EditableRoutePoint
        routePoint={routePoint}
        onRoutePointTimeChange={onRoutePointTimeChange}
        error={error}
        onSave={onSave}
        editAddressEnabled={editAddressEnabled}
        onEditAddress={onEditAddress}
        onCancelEditAddress={onAddressNotEditable}
        onAddressSuggestionSelected={handleAddressSuggestionSelected}
      />
    );
  } else {
    component = (
      <SavedRoutePoint
        routePoint={routePoint}
        error={error}
        editButton={editButton}
        deleteMidRoutePointButton={deleteMidRoutePointButton}
      />
    );
  }
  return (
    <Box pt="1.5rem" pr="1.5rem" pl="1.5rem" pb="1rem" boxShadow={1} display="flex" alignItems="center">
      {component}
    </Box>

  );
}
