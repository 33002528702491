import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { NewTabLink } from 'components/NewTabLink';
import { SHIPMENT_TRACKING_LINK_BLOG_URL } from '../../../../constants';
import { CurrencySelect } from '../../../CurrencySelect';
import { EquipmentTypeSelect } from '../../../EquipmentTypeSelect';
import { NumberInput } from '../../../NumberInput';
import { SubmitQuoteFormState } from './types';

interface Props{
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onValueChanged: <T,>(key: keyof SubmitQuoteFormState, value: T) => void;
  enableSubmit: boolean;
  onSubmit: VoidFunction;
  error: string | null;
  loading: boolean;
  quoteSubmitted: boolean;
  canSubmitQuote: boolean;
  formState: SubmitQuoteFormState;
  quoteRefetchLoading: boolean;
  onPopulateShipmentRequirements: VoidFunction;
}

export function SubmitQuoteForm(props: Props) {
  const {
    onValueChanged,
    enableSubmit,
    onSubmit,
    error,
    loading,
    quoteSubmitted,
    canSubmitQuote,
    formState,
    quoteRefetchLoading,
    onPopulateShipmentRequirements,
  } = props;
  const onEquipmentTypeChanged = (newValue: string) => {
    onValueChanged<string>('equipmentType', newValue);
  };
  const onCurrencyChanged = (newValue: string) => {
    onValueChanged<string>('currency', newValue);
  };

  const bigScreen = !useMediaQuery('(max-width:750px)');

  if (!canSubmitQuote) {
    return (
      <Box pt="1rem">
        <Typography variant="body1">You already have an active quote on this shipment. To submit a new quote, cancel the existing quote.</Typography>
      </Box>
    );
  }

  if (quoteRefetchLoading) {
    return (
      <Stack direction="row" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Box pt="1rem">
      <Tooltip title="Populate the form with the customer requirements. This will overwrite any existing data in the form.">
        <Button
          sx={{ mb: '.5rem' }}
          onClick={onPopulateShipmentRequirements}
        >
          Populate customer requirements
        </Button>
      </Tooltip>
      <Stack
        spacing={2}
        flexWrap="wrap"
        direction={bigScreen ? 'row' : 'column'}
        justifyContent="flex-start"
      >
        <NumberInput
          inputLabel="Price"
          defaultValue={formState.price}
          onChange={(val: string) => onValueChanged<string>('price', val)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        <CurrencySelect onChange={onCurrencyChanged} defaultValue="CAD" />
        <TextField
          id="datetime-local"
          label="Pickup ETA (DD-MM-YYYY)"
          type="date"
          value={formState.pickupStart}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => onValueChanged<string>('pickupStart', e.target.value)}
          required
        />
        <TextField
          id="datetime-local"
          label="Delivery ETA (DD-MM-YYYY)"
          type="date"
          value={formState.pickupEnd}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => onValueChanged<string>('pickupEnd', e.target.value)}
          required
        />
      </Stack>
      <Box mt=".3rem">
        <Typography variant="body2">Quotes are all-in (exclusive of tax) unless you explicitly state otherwise.</Typography>
      </Box>
      <Box mt="1rem" width="100%">
        <EquipmentTypeSelect onChange={onEquipmentTypeChanged} value={formState.equipmentType} />
      </Box>
      <Box mt="1rem">
        <TextField
          sx={{ width: '100%' }}
          onChange={(e) => onValueChanged<string>('notes', e.target.value)}
          label="Notes (Optional)"
          value={formState.notes}
        />
      </Box>
      <Stack direction="row" mt="1rem" alignItems="center">
        <Checkbox
          checked={formState.trackingLinkOffered}
          onChange={
  (event: React.ChangeEvent<HTMLInputElement>) => onValueChanged<boolean>('trackingLinkOffered', event.target.checked)
      }
        />
        <span>
          {'I can provide a tracking link for this shipment. '}
          <NewTabLink href={SHIPMENT_TRACKING_LINK_BLOG_URL}>
            <p>What are tracking links?</p>
          </NewTabLink>
        </span>
      </Stack>
      <Stack direction="column" spacing={1}>
        {error != null && !loading && <Typography color="red">{error}</Typography>}
        {quoteSubmitted
        && (
          <Typography color="green">Quote submitted successfully. </Typography>
        )}
      </Stack>
      <Stack sx={{ mt: '1.5rem' }}>
        {!loading ? (
          <Stack direction="row" spacing={2}>
            <Button
              sx={{ p: '1rem' }}
              onClick={onSubmit}
              variant="contained"
              disabled={!enableSubmit}
            >
              Submit Quote
            </Button>
          </Stack>
        ) : <Stack alignItems="center"><CircularProgress /></Stack>}
      </Stack>
    </Box>
  );
}
