import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Stack, Typography } from '@mui/material';
import { useRoleContext } from 'hooks';
import {
  ISOStringToLocalReadableTimestamp,
  capitalizeFirst,
  formatText,
  getNoteLabel,
  getQuoteSenderLabel,
  getQuoteTitle,
} from 'utils';
import { ISOStringToReadableNotLocalized } from '../../../../utils';
import { PartnerQuoteChip } from '../../../PartnerQuoteChip';
import { QuoteStatusBadge } from '../../../QuoteStatusBadge';
import { CarrierCompanyQuoteDetail } from '../ShipmentQuoteDetail/components/CarrierCompanyQuoteDetaill';

interface Props {
  quote: ShipperCondensedQuote
}

export function SingleShipmentQuoteDetail(props: Props) {
  const { quote } = props;
  const { role } = useRoleContext();
  return (
    <Stack
      id="quote-details-stack"
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      width="100%"
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <QuoteStatusBadge status={quote.internal_state} size="small" />
        <PartnerQuoteChip quote={quote} />
        <Typography variant="h4">{getQuoteTitle(quote)}</Typography>
      </Stack>
      <Typography variant="body1" color="#0c4985">{getQuoteSenderLabel(quote, role)}</Typography>
      <CarrierCompanyQuoteDetail quote={quote} />
      {
          quote?.internal_state === 'DECLINED' && quote?.decline_feedback && (
            <Typography>
              <b>Decline Reason: </b>
              {quote.decline_feedback}
            </Typography>
          )
        }
      <Typography>
        <b>Equipment: </b>
        {quote?.equipment_type ? capitalizeFirst(formatText(quote.equipment_type)) : '-'}
      </Typography>
      <Typography>
        <b>Pickup ETA: </b>
        { quote?.pickup_start ? ISOStringToReadableNotLocalized(quote.pickup_start) : '-'}
      </Typography>
      <Typography>
        <b>Delivery ETA: </b>
        { quote?.pickup_end ? ISOStringToReadableNotLocalized(quote.pickup_end) : '-'}
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography>
          <b>{'Tracking Link: '}</b>
          {quote?.tracking_link_offered ? 'Yes' : 'No'}
        </Typography>
        {quote.tracking_link_offered ? <LocationOnIcon fontSize="small" color="success" /> : <LocationOffIcon fontSize="small" />}
      </Stack>
      <Typography
        variant="body1"
        sx={{
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word',
          maxWidth: '100%',
        }}
      >
        <b>{`${getNoteLabel(quote, role)} `}</b>
        {quote?.notes ?? ''}
      </Typography>
      <Typography variant="body1" color="text.secondary">{`Submited ${ISOStringToLocalReadableTimestamp(quote.created_at)}`}</Typography>
    </Stack>
  );
}
