import {
  Button, Chip, TableCell, TableRow, Typography,
} from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';

interface Props {
  carrier: FollowingCarrier,
  onUnfollowCarrierClicked: (carrier: FollowingCarrier) => void,

}

export function ShipperNetworkTableRow(props: Props) {
  const { carrier, onUnfollowCarrierClicked } = props;

  return (
    <TableRow
      key={carrier?.id}
    >
      <TableCell>
        <Typography variant="body1">
          {carrier?.user?.first_name}
          {' '}
          {carrier?.user?.last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <NewTabLink href={`/companies/${carrier?.company?.id}/profile`}>
          <Typography variant="body1">{carrier?.company?.name || ''}</Typography>
        </NewTabLink>
      </TableCell>
      <TableCell>
        <Chip color="success" label="Approved" />
      </TableCell>
      <TableCell>
        <Button variant="outlined" onClick={() => onUnfollowCarrierClicked(carrier)}>
          Remove connection
        </Button>
      </TableCell>
    </TableRow>
  );
}
