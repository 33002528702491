import {
  Alert, Button, Stack, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { NewTabLink } from 'components/NewTabLink';
import { SuccessTypography } from 'components/SuccessTypography';
import { useRoleContext, useWriterComponentState } from 'hooks';
import { useState } from 'react';
import { DEVELOPER_DOCS_URL } from '../../constants';

interface CreateUserAPIKeyResponse {
  api_key: string;
}

export function CreateAPIKeyForUserMenu() {
  const { role } = useRoleContext();
  const [createdKey, setCreatedKey] = useState<string | null>(null);

  const {
    write, error, loading, success,
  } = useWriterComponentState({
    errorMessage: 'Failed to create API key. Please contact us directly using the chat bubble.',
    writeConfig: {
      method: 'post',
      url: '/user/api-key',

    },
  });

  if (role == null || role.type !== 'SHIPPER') {
    return (<div />);
  }

  return (
    <Stack direction="column" mt=".5rem" mb="1rem" spacing={1}>
      <Typography variant="body1">
        Provisioning a key grants access to the Evotrux API.
        If you want to integrate your systems with Evotrux, please see the
        {' '}
        <NewTabLink href={DEVELOPER_DOCS_URL}>
          <p>developer documentation.</p>
        </NewTabLink>
      </Typography>
      <Alert severity="info">Creating a key will deactivate any existing keys for your account.</Alert>
      <Stack direction="row" justifyContent="flex-start" justifyItems="flex-start">
        <LoadingWrappedNode loading={loading}>
          <Button
            sx={{ mt: '1rem' }}
            variant="contained"
            onClick={() => write<CreateUserAPIKeyResponse>((res) => setCreatedKey(res.api_key))}
          >
            Create API Key
          </Button>
        </LoadingWrappedNode>
      </Stack>
      <ErrorTypography error={error} />
      <SuccessTypography success={success} message={`Key created: ${createdKey}`} />
    </Stack>
  );
}
