import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from '@mui/material';

interface Props {
  href: string;
  children: React.ReactNode;
}

export function NewTabLink(props: Props) {
  const { href, children } = props;
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'underline',
      }}
    >
      {children}
      <LaunchIcon fontSize="small" />
    </Link>
  );
}
