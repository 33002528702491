import { Typography } from '@mui/material';
import { NewTabLink } from 'components/NewTabLink';
import { useAPI, useRoleContext, useSubscriptionStatus } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

export function UpdatePaymentInformationButton() {
  const { api } = useAPI();
  const { company } = useRoleContext();
  const { subscription } = useSubscriptionStatus();
  const [paymentUpdateUrl, setPaymentUpdateUrl] = useState<string | null>(null);

  const getPaymentMethodUpdateUrl = useCallback(() => {
    if (subscription && subscription?.subscription_plan === 'SHIPPER_PAY_PER_POSTING' && company) {
      return `/stripe/companies/ppp/${company.id}/payment-method-update?source=button-prefetch`;
    } if (subscription && subscription?.subscription_plan === 'CARRIER_PAY_PER_BOOKING' && company) {
      return `/stripe/companies/ppb/${company.id}/payment-method-update?source=button-prefetch`;
    }
    return '/';
  }, [subscription, company]);

  useEffect(() => {
    if (company && (subscription?.subscription_plan === 'SHIPPER_PAY_PER_POSTING' || subscription?.subscription_plan === 'CARRIER_PAY_PER_BOOKING')
    && paymentUpdateUrl == null) {
      api<CreatePaymentMethodUpdateSessionResponse>(
        'post',
        getPaymentMethodUpdateUrl(),
      ).then((response) => {
        setPaymentUpdateUrl(response.data.url);
      });
    }
  }, [setPaymentUpdateUrl,
    paymentUpdateUrl,
    api,
    company, getPaymentMethodUpdateUrl, subscription]);

  if (paymentUpdateUrl == null) {
    return <div />;
  }

  return (
    <NewTabLink href={paymentUpdateUrl}>
      <Typography>Add payment method.</Typography>
    </NewTabLink>
  );
}
