import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Button,
  CircularProgress,
  Modal, Stack, TextField, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { NewTabLink } from 'components/NewTabLink';
import { SuccessTypography } from 'components/SuccessTypography';
import { useCarrierShipmentPageContext, useWriterComponentState } from 'hooks';
import { useEffect, useState } from 'react';
import { isValidHttpUrl } from 'utils';
import { SHIPMENT_TRACKING_LINK_BLOG_URL } from '../../../constants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

export function AddShipmentTrackingLinkModal(props: Props) {
  const { isOpen, onClose } = props;
  const { shipment, onRefresh } = useCarrierShipmentPageContext();
  const [url, setUrl] = useState<string>('');
  const [urlIsValid, setUrlIsValid] = useState<boolean>(true);
  const {
    loading,
    error,
    write,
    success,
    resetState,
  } = useWriterComponentState({
    errorMessage: 'Failed to create tracking link. Please contact us if the issue persists.',
    writeConfig: {
      method: 'post',
      url: shipment !== undefined ? `/shipments/${shipment.info.id}/tracking-links` : '/',
      body: { url },
    },
    errorMessageKey: 'message',
  });

  useEffect(() => {
    if (success && isOpen) {
      if (onRefresh) {
        onRefresh();
      }
      onClose();
      resetState();
    }
  }, [success, onRefresh, onClose, isOpen, resetState]);

  useEffect(() => {
    setUrlIsValid(isValidHttpUrl(url));
  }, [url]);
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        <Stack justifyContent="flex-end" direction="row">
          <CloseIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={onClose}
          />
        </Stack>
        <Stack direction="column" spacing={2}>
          <Typography variant="h4" component="h2" sx={{ mb: '1rem' }}>Add Tracking Link</Typography>
          <Typography variant="h6">Enter the URL of your load tracking software for the shipper to see.</Typography>

          <TextField
            fullWidth
            label="Tracking URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <ErrorTypography error={urlIsValid ? null : 'Invalid URL. URLs must begin with https:// '} />
          <span>
            {'Learn more about tracking links '}
            <NewTabLink href={SHIPMENT_TRACKING_LINK_BLOG_URL}>
              <Typography>here.</Typography>
            </NewTabLink>
          </span>
          {loading ? <Stack><CircularProgress /></Stack>
            : (
              <Button
                disabled={!urlIsValid}
                variant="contained"
                onClick={() => write()}
              >
                Submit
              </Button>
            )}
          <SuccessTypography success={success} message="Tracking link created." />
          <ErrorTypography error={error} />
        </Stack>
      </Box>
    </Modal>
  );
}
