import { Stack, Typography, useMediaQuery } from '@mui/material';
import { QuoteQA } from '../../../QuoteQA';
import { MultiShipmentProjectQuoteDetail } from '../MultiShipmentProjectQuoteDetail';
import { SingleShipmentQuoteDetail } from '../SingleShipmentQuoteDetail';

interface Props {
  quote: CondensedQuote | ShipperCondensedQuote;
}

export function QuoteDetail(props: Props) {
  const {
    quote,
  } = props;
  const useColumnLayout = useMediaQuery('(max-width:900px)');

  let quoteDetailsComponent = (
    <SingleShipmentQuoteDetail
      quote={quote}
      useColumnLayout={useColumnLayout}
    />
  );
  if (quote?.quote_type === 'MULTI_SHIPMENT_PROJECT') {
    quoteDetailsComponent = (
      <MultiShipmentProjectQuoteDetail
        quote={quote}
        useColumnLayout={useColumnLayout}
      />
    );
  }

  if (useColumnLayout) {
    return (
      <Stack
        direction="column"
      >
        {quoteDetailsComponent}
        <Typography variant="h6" mt="1rem">Questions & Answers</Typography>
        <Stack
          direction="column"
          id="quote-qa-container"
          pr="1rem"
          flexWrap="wrap"
        >
          <QuoteQA quote={quote} />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      id="carrier-quote-detail-container"
      direction="row"
      mb="1rem"
      justifyContent="start"
      spacing={5}
    >
      <Stack width="60%">
        {quoteDetailsComponent}
      </Stack>
      <Stack
        id="quote-qa-container"
        width="40%"
      >
        <QuoteQA quote={quote} />
      </Stack>
    </Stack>
  );
}
