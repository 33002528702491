import { useAuthContext, useRoleContext, useSubscriptionStatus } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { inLocalDevEnvironment, ISOStringToUnixEpoch } from 'utils';

interface CustomerIOUserObject {
  // user attr
  id: string; // cognito id is used.
  email: string;
  created_at: number | null; // When a person first signed up in Unix epoch format.
  first_name: string;
  last_name: string;

  // role/company
  role_type: RoleValue | '';
  company_id: number | null;
  is_unverified_carrier: boolean;

  // subscription
  subscription_plan_name: string;

}

export function CustomerIOWrapper() {
  const { user } = useAuthContext();
  const { role, company } = useRoleContext();
  const { subscription } = useSubscriptionStatus();

  const [customerIOUser, setCustomerIOUser] = useState<CustomerIOUserObject>({
    id: user?.cognito_id || '',
    email: user?.email || '',
    created_at: user != null ? ISOStringToUnixEpoch(user.created_at) : null,
    role_type: role != null && role?.type ? role.type : '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    subscription_plan_name: subscription && subscription?.subscription_plan ? subscription.subscription_plan : '',
    company_id: company != null ? company.id : null,
    is_unverified_carrier: company != null ? company.verified_at == null : false,
  });
  const [identifiedUser, setIdentifiedUser] = useState<boolean>(false);

  const identifyUser = useCallback(() => {
    if (!inLocalDevEnvironment()) {
    // eslint-disable-next-line
    const _cio = (window as any)._cio || [];
      // eslint-disable-next-line
    (window as any)._cio = _cio;
      if (_cio && _cio?.identify) {
        _cio.identify(customerIOUser);
      }
    }
  }, [customerIOUser]);

  useEffect(() => {
    if (subscription != null && subscription.subscription_plan && customerIOUser.subscription_plan_name === '') {
      setCustomerIOUser((prev) => ({
        ...prev,
        subscription_plan_name: subscription.subscription_plan,
      }));
    }
  }, [subscription, customerIOUser]);

  useEffect(() => {
    if (company != null && customerIOUser.company_id == null) {
      setCustomerIOUser((prev) => ({
        ...prev,
        company_id: company != null ? company.id : null,
        is_unverified_carrier: company != null ? company.verified_at == null : false,
      }));
    }
  }, [company, customerIOUser]);

  useEffect(() => {
    if (user != null && customerIOUser.email === '') {
      setCustomerIOUser((prev) => ({
        ...prev,
        id: user.cognito_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        created_at: ISOStringToUnixEpoch(user.created_at),
      }));
    }
  }, [user, customerIOUser]);

  useEffect(() => {
    if (role != null && customerIOUser.role_type === '') {
      setCustomerIOUser((prev) => ({
        ...prev,
        role_type: role.type,
      }));
    }
  }, [role, customerIOUser]);

  useEffect(() => {
    if (!identifiedUser && customerIOUser.id !== ''
    && customerIOUser.subscription_plan_name !== ''
    && customerIOUser.role_type !== '' && customerIOUser.email !== '' && customerIOUser.created_at != null) {
      identifyUser();
      setIdentifiedUser(true);
    }
  }, [customerIOUser, identifiedUser, setIdentifiedUser, identifyUser]);

  return (
    <div />
  );
}
